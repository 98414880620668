import React from 'react';
import { Grid, Typography } from '@mui/material';
import PlantMaintenanceServices from './services';
import TwoColumnGrid from '../../../components/StyledGrids/two_column_grid';

import PlantMaintenancePricing from './pricing';
import HeroImage from '../../../components/heroImage';

const PlantMaintenance: React.FC = () => {
  return (
    <>
      <HeroImage imageName="laurie_holding_plant" photoCreditKey="morgann" />

      <div className="contentFrame">
        <Grid container spacing={2} sx={{ padding: '0 20px' }}>
          <Grid item xs={12}>
            <Typography variant="h1">Plant Maintenance</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Our services cover everything needed to keep your plants healthy
              and vibrant, including watering, fertilization, pest and disease
              management, delivery, installation, removal, and ongoing plant
              care consultations. We customize our approach to meet your unique
              needs, following a precise schedule to ensure your plants are
              always thriving. You get beautiful plants… we get to play in the
              dirt.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Our goal is to ensure that your indoor environments are vibrant,
              healthy, and aesthetically pleasing year-round. We offer a range
              of plant maintenance services tailored to meet your unique needs.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TwoColumnGrid
              leftContent={<PlantMaintenanceServices />}
              rightContent={<PlantMaintenancePricing />}
              spaceBetween
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PlantMaintenance;
